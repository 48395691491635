import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdMoreVert,
} from "react-icons/md";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import "./App.scss";
import Login from "./Login";

export const rootUrl: string = "https://apiv1.guug.com.br";
// export const rootUrl: string = "http://192.168.1.73:3002";
// export const rootUrl: string = "https://dev.guug.com.br";

export const cleanContract: string = rootUrl + "/clean_contract";
export const cleanersURL: string = rootUrl + "/cleaner";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

interface ResidenceInfo {
  id: number;
  name: string;
}

export type User = {
  id: number;
  name: string;
  surname: string | null;
  phone: string;
  apartment: ResidenceInfo;
  condominium: ResidenceInfo;
  residential: ResidenceInfo;
  tower: ResidenceInfo;
};

type StatusContract =
  | "CANCELED"
  | "WAITING CONFIRMATION"
  | "WAITING PAYMENT"
  | "BOOKED"
  | "WAITING REVIEW"
  | "PAY EMPLOYEE"
  | "DONE";

interface CleanerEmployee {
  bankAccount: string;
  bankAgency: string;
  bankName: string;
  city: string;
  complement: string;
  cpf: string;
  createdAt: Date;
  dom: string;
  houseNumber: string;
  id: number;
  name: string;
  neighborhood: string;
  qua: string;
  qui: string;
  rate: number;
  rg: string;
  sab: string;
  seg: string;
  sex: string;
  state: string;
  street: string;
  ter: string;
  updatedAt: Date;
}

export interface CleanContractObject {
  id: number;
  cleaner?: CleanerEmployee;
  userId: number;
  employeeId: number;
  serviceDateStart: Date;
  houseSize: string;
  totalTimeHired: number;
  totalValue: number;
  status: StatusContract;
  payedByUser: boolean;
  guugPayEmployee: boolean;
  rate: number;
  comment: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
}

interface RadioOption {
  label: string;
  value: string;
}

Modal.setAppElement(document.getElementById("root") as HTMLElement);

function App() {
  const userJson = localStorage.getItem("guugLimpa.user");

  const [cleanContractCanceledList, setCleanContractCanceledList] = useState<
    CleanContractObject[]
  >([]);
  const [cleanContractDoneList, setCleanContractDoneList] = useState<
    CleanContractObject[]
  >([]);
  const [cleanContractConfirmationList, setCleanContractConfirmationList] =
    useState<CleanContractObject[]>([]);

  const [cleanContractPaymentList, setCleanContractPaymentList] = useState<
    CleanContractObject[]
  >([]);
  const [cleanContractBookedList, setCleanContractBookedList] = useState<
    CleanContractObject[]
  >([]);
  const [cleanContractEmployeeList, setCleanContractEmployeeList] = useState<
    CleanContractObject[]
  >([]);
  const [cleanContractReviewList, setCleanContractReviewList] = useState<
    CleanContractObject[]
  >([]);

  const [selectedContract, setSelectedContract] =
    useState<CleanContractObject>();

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [allCleaners, setAllCleaners] = useState<CleanerEmployee[]>([]);

  const [selectedCleaner, setSelectedCleaner] = useState<CleanerEmployee>();

  const [tableType, setTableType] = useState<StatusContract>("WAITING PAYMENT");

  const itemsPerPage = 10;
  const [currentRange, setCurrentRange] = useState({
    firstIndex: 0,
    lastIndex: itemsPerPage,
  });
  const [currentContracts, setCurrentContracts] = useState<
    CleanContractObject[]
  >([]);
  const [filteredContracts, setFilteredContracts] = useState<
    CleanContractObject[]
  >([]);
  const [lastPage, setLastPage] = useState<number>(0);

  const [search, setSearch] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("client");
  const [searchNotFound, setSearchNotFound] = useState<boolean>(false);

  const openModal = useCallback((contract: CleanContractObject) => {
    setSelectedContract(contract);
    getAllCleaners();
    setIsOpen(true);
  }, []);

  function closeModal() {
    setSelectedContract(undefined);
    setSelectedCleaner(undefined);
    setIsOpen(false);
  }

  const getToken = () => localStorage.getItem("guugLimpa.jwtToken");

  const nearFirstPastLast = (array?: CleanContractObject[]) => {
    if (!array) return [];
    else {
      const sortedArray = array.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      let arrayNear: CleanContractObject[] = [];
      let pastArray: CleanContractObject[] = [];
      let findIndex = sortedArray.findIndex((value) => {
        if (moment(value.serviceDateStart).isSameOrAfter(moment(), "day"))
          return value;
      });
      if (findIndex != -1) {
        pastArray = sortedArray.slice(0, findIndex);
        arrayNear = sortedArray.slice(findIndex);
        return arrayNear.concat(pastArray);
      } else {
        return sortedArray;
      }
    }
  };

  const getAllCleaners = async () => {
    let cleaners = await fetch(`${cleanersURL}/getAll`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let allCleaners = await cleaners.json();
    setAllCleaners(allCleaners);
  };

  const getAllContract = useCallback(async () => {
    let response = await fetch(`${cleanContract}/getAll`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const allContract: CleanContractObject[] = await response.json();
    let groupedContracts = _.groupBy(allContract, "status");

    setCleanContractCanceledList(
      nearFirstPastLast(groupedContracts["CANCELED"])
    );
    setCleanContractConfirmationList(
      nearFirstPastLast(groupedContracts["WAITING CONFIRMATION"])
    );
    setCleanContractPaymentList(
      nearFirstPastLast(groupedContracts["WAITING PAYMENT"])
    );
    setCleanContractBookedList(nearFirstPastLast(groupedContracts["BOOKED"]));
    setCleanContractReviewList(
      nearFirstPastLast(groupedContracts["WAITING REVIEW"])
    );
    setCleanContractEmployeeList(
      nearFirstPastLast(groupedContracts["PAY EMPLOYEE"])
    );
    setCleanContractDoneList(nearFirstPastLast(groupedContracts["DONE"]));
  }, []);

  const confirmUserPayment = useCallback(
    async (contract: CleanContractObject) => {
      let allContracts = await fetch(`${cleanContract}/paymentConfirm`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          employeeId: contract.employeeId,
          id: contract.id,
          userId: contract.userId,
        }),
      });
      getAllContract();
    },
    [getAllContract]
  );

  const cancelContract = useCallback(
    async (contract: CleanContractObject) => {
      let allContracts = await fetch(`${cleanContract}/cancelContract`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: contract.id,
        }),
      });
      getAllContract();
    },
    [getAllContract]
  );

  const attachCleaner = useCallback(
    async (
      contract: CleanContractObject,
      employeeAttach: number,
      employeeName: string
    ) => {
      let allContracts = await fetch(`${cleanContract}/attachCleaner`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payedByUser: contract.payedByUser,
          employeeId: employeeAttach,
          id: contract.id,
          serviceDateStart: contract.serviceDateStart,
          employeeName,
          userId: contract.userId,
        }),
      });
      getAllContract();
    },
    [getAllContract]
  );

  const jobExecuted = useCallback(
    async (contract: CleanContractObject) => {
      let allContracts = await fetch(`${cleanContract}/jobExecuted`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: contract.id,
          serviceDateStart: contract.serviceDateStart,
          userId: contract.userId,
        }),
      });
      getAllContract();
    },
    [getAllContract]
  );

  const payEmployee = useCallback(
    async (contract: CleanContractObject) => {
      let allContracts = await fetch(`${cleanContract}/payEmployee`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: contract.id,
        }),
      });
      getAllContract();
    },
    [getAllContract]
  );

  const cancelPayment = useCallback(
    async (contract: CleanContractObject) => {
      let allContracts = await fetch(`${cleanContract}/cancelPayment`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: contract.id,
        }),
      });
      getAllContract();
    },
    [getAllContract]
  );

  const statusContract = useCallback(
    (contract: CleanContractObject) => {
      switch (contract.status) {
        case "CANCELED":
          return;
        case "WAITING CONFIRMATION":
          return (
            <div className="actions">
              <button
                onClick={() => {
                  openModal(contract);
                }}
              >
                Atribuir Diarista
              </button>
              <button
                onClick={() => {
                  cancelPayment(contract);

                  setTableType("WAITING PAYMENT");
                }}
              >
                Devolver Pagamento
              </button>
            </div>
          );
        case "WAITING PAYMENT":
          return (
            <div className="actions">
              <button
                onClick={() => {
                  confirmUserPayment(contract);

                  setTableType("WAITING CONFIRMATION");
                }}
              >
                Pagamento Realizado
              </button>
              <button
                onClick={() => {
                  cancelContract(contract);

                  setTableType("CANCELED");
                }}
              >
                Cancelar Contrato
              </button>
            </div>
          );
        case "BOOKED":
          return (
            <div className="actions">
              <button
                onClick={() => {
                  jobExecuted(contract);

                  setTableType("PAY EMPLOYEE");
                }}
              >
                Serviço executado
              </button>
              <button
                onClick={() => {
                  cancelPayment(contract);

                  setTableType("WAITING PAYMENT");
                }}
              >
                Devolver Pagamento
              </button>
            </div>
          );
        case "WAITING REVIEW":
          return;
        case "PAY EMPLOYEE":
          return (
            <div className="actions">
              <button
                onClick={() => {
                  payEmployee(contract);

                  setTableType("WAITING REVIEW");
                }}
              >
                Pago ao funcionário
              </button>
            </div>
          );
        case "DONE":
          return;
        default:
          return;
      }
    },
    [
      cancelContract,
      cancelPayment,
      confirmUserPayment,
      jobExecuted,
      openModal,
      payEmployee,
    ]
  );

  const rowView = useCallback(
    (contract: CleanContractObject) => {
      return (
        <tr className="row" key={contract.id}>
          <td>
            {contract.user.name + " " + contract.user.surname}
            <br />
            {`(${contract.user.phone.slice(0, 2)}) ${contract.user.phone.slice(
              2,
              7
            )}-${contract.user.phone.slice(7)}`}
          </td>
          <td>{contract.user.residential?.name ?? ""}</td>
          <td>{contract.user.condominium?.name ?? ""}</td>
          <td>{contract.user.tower?.name ?? ""}</td>
          <td>{contract.user.apartment?.name ?? ""}</td>
          <td>{moment(contract.createdAt).format("DD/MM/YYYY - HH:mm")}</td>
          <td>
            {moment(contract.serviceDateStart).format("DD/MM/YYYY - HH:mm")}
          </td>
          <td>
            {moment(contract.serviceDateStart)
              .add(contract.totalTimeHired, "h")
              .format("DD/MM/YYYY - HH:mm")}
          </td>
          <td>{contract.totalValue},00</td>
          <td>{contract.cleaner ? contract.cleaner.name : "-"}</td>
          <td>
            {statusContract(contract) ? (
              <button className="row-actions">
                <MdMoreVert />
                {statusContract(contract)}
              </button>
            ) : (
              "-"
            )}
          </td>
        </tr>
      );
    },
    [statusContract]
  );

  const handleFilter = useCallback(
    (search: string, searchOption: string) => {
      const filtered = currentContracts.filter((contract) => {
        if (search && searchOption) {
          const lowerCaseSearch = search.toLocaleLowerCase();

          switch (searchOption) {
            case "client":
              return (
                contract.user &&
                contract.user.name.toLowerCase().includes(lowerCaseSearch)
              );
            case "residential":
              return (
                contract.user?.residential &&
                contract.user.residential.name
                  .toLowerCase()
                  .includes(lowerCaseSearch)
              );
            case "condominium":
              return (
                contract.user?.condominium &&
                contract.user.condominium.name
                  .toLowerCase()
                  .includes(lowerCaseSearch)
              );
            case "tower":
              return (
                contract.user?.tower &&
                contract.user.tower.name.toLowerCase().includes(lowerCaseSearch)
              );
            case "apartment":
              return (
                contract.user?.apartment &&
                contract.user.apartment.name
                  .toLowerCase()
                  .includes(lowerCaseSearch)
              );
            case "createdAt":
              return (
                contract.createdAt &&
                moment(contract.createdAt)
                  .format("DD/MM/YYYY - HH:mm")
                  .toLowerCase()
                  .includes(lowerCaseSearch)
              );
            case "initialDate":
              return (
                contract.serviceDateStart &&
                moment(contract.serviceDateStart)
                  .format("DD/MM/YYYY - HH:mm")
                  .toLowerCase()
                  .includes(lowerCaseSearch)
              );
            case "endDate":
              return (
                contract.serviceDateStart &&
                moment(contract.serviceDateStart)
                  .add(contract.totalTimeHired, "h")
                  .format("DD/MM/YYYY - HH:mm")
                  .toLowerCase()
                  .includes(lowerCaseSearch)
              );
            case "price":
              return (
                contract.totalValue &&
                `${contract.totalValue},00`
                  .toLowerCase()
                  .includes(lowerCaseSearch)
              );
            case "employee":
              return (
                contract.cleaner &&
                contract.cleaner.name.toLowerCase().includes(lowerCaseSearch)
              );
          }
        }
      });

      setFilteredContracts(filtered);
      setSearchNotFound(filtered.length === 0);
    },
    [currentContracts]
  );

  useEffect(() => {
    switch (tableType) {
      case "CANCELED":
        setCurrentContracts(cleanContractCanceledList);
        break;
      case "WAITING CONFIRMATION":
        setCurrentContracts(cleanContractConfirmationList);
        break;
      case "WAITING PAYMENT":
        setCurrentContracts(cleanContractPaymentList);
        break;
      case "BOOKED":
        setCurrentContracts(cleanContractBookedList);
        break;
      case "WAITING REVIEW":
        setCurrentContracts(cleanContractReviewList);
        break;
      case "PAY EMPLOYEE":
        setCurrentContracts(cleanContractEmployeeList);
        break;
      case "DONE":
        setCurrentContracts(cleanContractDoneList);
        break;
    }
  }, [
    cleanContractBookedList,
    cleanContractCanceledList,
    cleanContractConfirmationList,
    cleanContractDoneList,
    cleanContractEmployeeList,
    cleanContractPaymentList,
    cleanContractReviewList,
    tableType,
  ]);

  useEffect(() => {
    getAllContract();
  }, [getAllContract]);

  useEffect(() => {
    const isSearchNotFound = filteredContracts.length > 0;

    const contractsSize = isSearchNotFound
      ? filteredContracts.length
      : currentContracts.length;

    const lastPage = Math.ceil(contractsSize / itemsPerPage);
    setLastPage(lastPage);
  }, [currentContracts, filteredContracts]);

  useEffect(() => {
    if (searchNotFound) setTimeout(() => setSearchNotFound(false), 1500);
  }, [searchNotFound]);

  useEffect(() => {
    if (userJson) {
      fetch(rootUrl + "/user/loginPersistent", {
        body: userJson,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) =>
        res
          .json()
          .then((data) =>
            localStorage.setItem("guugLimpa.jwtToken", data.token)
          )
      );
    }
  }, []);

  const radioOptions: RadioOption[] = [
    { value: "client", label: "Cliente" },
    { value: "residential", label: "Residencial" },
    { value: "condominium", label: "Condomínio" },
    { value: "tower", label: "Torre" },
    { value: "apartment", label: "Apartamento" },
    { value: "createdAt", label: "Data do pedido" },
    { value: "initialDate", label: "Início" },
    { value: "endDate", label: "Fim" },
    { value: "price", label: "Preço" },
    { value: "employee", label: "Contratado" },
  ];

  if (userJson)
    return (
      <div className="App">
        <div className="select-container">
          <label htmlFor="table-type">Desejo visualizar:&nbsp;&nbsp;</label>
          <select
            name="table-type"
            id="table-type"
            value={tableType}
            onChange={(e) => {
              setTableType(e.target.value as StatusContract);

              setSearch("");
            }}
          >
            <option defaultChecked value="WAITING PAYMENT">
              Aguardando pagamento
            </option>
            <option value="WAITING CONFIRMATION">
              Pago, necessita atribuir diarista
            </option>
            <option value="BOOKED">Aguardando execução do serviço</option>
            <option value="WAITING REVIEW">
              A serem revisados pelos usuários
            </option>
            <option value="PAY EMPLOYEE">
              Funcionários aguardando pagamento
            </option>
            <option value="DONE">Finalizados</option>
            <option value="CANCELED">Cancelados</option>
          </select>
        </div>
        {currentContracts.length > 0 ? (
          <>
            <main className="table-container">
              <div className="search-container">
                <div className="options-container">
                  {radioOptions.map((option) => (
                    <div className="radio-container">
                      <input
                        id={`option-${option.value}`}
                        defaultChecked={option.value === "client"}
                        name="search-option"
                        type="radio"
                        value={option.value}
                        onChange={(e) => {
                          setSearchOption(e.target.value);

                          handleFilter(search, e.target.value);

                          setSearch("");
                        }}
                      />
                      <label htmlFor={`option-${option.value}`}>
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
                <input
                  placeholder="Filtrar por..."
                  type="text"
                  onChange={(e) => {
                    setSearch(e.target.value);

                    handleFilter(e.target.value, searchOption);
                  }}
                  value={search}
                />

                {searchNotFound && (
                  <small className="not-found-message">
                    Nenhum resultado encontrado para a busca.
                  </small>
                )}
              </div>
              <table className="contracts-table">
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Residencial</th>
                    <th>Condomínio</th>
                    <th>Torre</th>
                    <th>Apartamento</th>
                    <th>Data do pedido</th>
                    <th>Início</th>
                    <th>Fim</th>
                    <th>Preço</th>
                    <th>Contratado</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {(filteredContracts.length > 0
                    ? filteredContracts
                    : currentContracts
                  )
                    .slice(currentRange.firstIndex, currentRange.lastIndex)
                    .map((contract) => rowView(contract))}
                </tbody>
              </table>
            </main>
            <ReactPaginate
              containerClassName="pagination"
              breakLabel="..."
              nextLabel={<MdChevronRight />}
              onPageChange={(item) => {
                const currentPage = item.selected;

                const firstIndex = itemsPerPage * currentPage;
                const lastIndex = firstIndex + itemsPerPage;

                setCurrentRange({
                  firstIndex,
                  lastIndex,
                });
              }}
              pageRangeDisplayed={5}
              pageCount={lastPage}
              previousLabel={<MdChevronLeft />}
            />
          </>
        ) : (
          <p className="no-data">
            Não há nada para ser exibido na categoria selecionada :(
          </p>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Atribuir Diarista"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h2>Atribuir Diarista</h2>
            <MdClose
              fontSize={30}
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            >
              Fechar
            </MdClose>
          </div>
          <div
            style={{
              maxHeight: 300,
              borderWidth: 1,
              borderRadius: 10,
              borderStyle: "solid",
              padding: 10,
              overflowY: "auto",
            }}
          >
            {allCleaners
              ? allCleaners.map((cleaner) => {
                  return (
                    <div
                      key={cleaner.id}
                      onClick={() => {
                        setSelectedCleaner(cleaner);
                      }}
                      style={{
                        color:
                          selectedCleaner?.id == cleaner.id
                            ? "#ff4f00"
                            : "black",
                        cursor: "pointer",
                      }}
                    >
                      {cleaner.name}
                    </div>
                  );
                })
              : "Sem funcionários cadastrados."}
          </div>
          <p>Funcionário selecionado: {selectedCleaner?.name}</p>
          <p>Tamanho: {selectedContract?.houseSize}</p>
          <p>
            Inicio: {moment(selectedContract?.serviceDateStart).format("LLL")}
          </p>
          <p>
            Fim:{" "}
            {moment(selectedContract?.serviceDateStart)
              .add(selectedContract?.totalTimeHired, "h")
              .format("LLL")}
          </p>
          <button
            style={{ width: "100%" }}
            onClick={() => {
              if (selectedContract && selectedCleaner) {
                attachCleaner(
                  selectedContract,
                  selectedCleaner.id,
                  selectedCleaner.name
                );
                closeModal();

                setTableType("BOOKED");
              }
            }}
            disabled={!selectedCleaner}
          >
            Confirmar
          </button>
        </Modal>
      </div>
    );

  return <Login />;
}

export default App;
