import React, { useState } from "react";
import "./App.scss";
import { rootUrl } from "./App";

export default function Login() {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  return (
    <form
      className="login-box"
      onSubmit={async (e) => {
        e.preventDefault();

        if (phone === "8888888888888") {
          const res = await fetch(rootUrl + "/user/login", {
            body: JSON.stringify({
              phone,
              password,
            }),
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          const data = await res.json();

          if (data.message) return alert("Erro! Senha incorreta");

          localStorage.setItem("guugLimpa.jwtToken", data.token);

          localStorage.setItem("guugLimpa.user", JSON.stringify(data));

          // eslint-disable-next-line no-restricted-globals
          location.reload();

          return;
        }

        alert("Erro! Telefone incorreto");
      }}
    >
      <input
        placeholder="Telefone"
        className="login-input"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        type="text"
      />
      <input
        placeholder="Senha"
        className="login-input"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      />

      <button className="login-button">Entrar</button>
    </form>
  );
}
